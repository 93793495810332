body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  background-color: #faf3e0;
}

.nav-button {
  color: #00000080;
  transition: color 0.3s, background-color 0.3s;
}

.nav-button:hover {
  color: #000000;
}

.active-button {
  color: #ffffff !important;
  background-color: #ff511a !important;
}

.active-button:hover {
  background-color: #ff521ba3 !important;
}
