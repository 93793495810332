
.footer {
    background-color: #ff511a;
    color: white;
    padding: 1rem;
    text-align: center;
    width: 100%;
    position: static;
    margin-top: auto;
}

@media (max-width: 600px) {
    .footer {
        padding: 2rem;
    }
}